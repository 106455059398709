import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import { Chip } from '@material-ui/core';
import Toolbar from '../../../components/toolbar/Toolbar';
import UserService from '../../../services/UserService';
import ServerSideList from '../../../components/server-side-list/ServerSideList';
import SwitchW from '../../../components/wrapper/SwitchW';
import { useToast } from '../../../components/context/toast/ToastContext';
import { StyledCard } from '../../../components/context/ThemeChangeContext';
import { FLUX_USERS } from '../../../components/context/FluxContext';
import FormDialog, { EDIT_USER } from '../../../components/form-generic/FormDialog';
import { useUserChange } from '../../../components/context/UserChangeContext';
// import RegistrationService from '../../../services/RegistrationService';
import EditionService from '../../../services/EditionService';
import UserRoleType from '../../../enums/UserRoleType';

const UserList = (props) => {
  const { isInternalPage = false } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { isSecretary, currentUser } = useUserChange();

  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [link, setLink] = useState(null);
  const [internalID, setInternalID] = useState(undefined);
  // const [chips, setChips] = useState({});
  const [editions, setEditions] = useState([]);

  useEffect(() => {
    if (editions.length === 0) {
      EditionService.findAllList().then((resp) => {
        if (resp.status === 200) {
          setEditions(resp.data);
        }
      });
    }
  }, [editions]);

  const changeEnabled = useCallback(
    (dataIndex) => {
      const ed = _.clone(data[dataIndex]);
      UserService.changeEnable(ed.id).then((response) => {
        if (response.status === 200) {
          const newData = _.clone(data);
          newData[dataIndex] = response.data;
          setData(newData);
          return;
        }
        addToast({ body: t('toastes.userEnabledError'), type: 'error' });
      });
    },
    [addToast, data, t]
  );

  // useEffect(() => {
  //   if (data && editions) {
  //     data.forEach((user) => {
  //       editions.forEach((edition) => {
  //         RegistrationService.findOneByEditionAndUser(edition.id, user.id).then((resp) => {
  //           if (resp.status === 200) {
  //             const allChips = chips;
  //             const userChip = allChips[user.id] ? allChips[user.id] : [];
  //             if (!userChip.find((value) => value === edition.shortName)) {
  //               userChip.push(edition.shortName);
  //               allChips[user.id] = userChip;
  //               setChips(allChips);
  //             }
  //           }
  //         });
  //       });
  //     });
  //   }
  // }, [chips, data, editions]);

  // const getNameUserRole = (userRole) => t(`enums.userRoleType.${userRole}`) ?? 'não definido';

  // console.log(data);

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: `${t('pages.userList.columns.name')}/${t('pages.userList.columns.tagName')}`,
        options: {
          customBodyRenderLite: (dataIndex) => (
            <p>
              {data[dataIndex].name}
              <br />
              <small>
                {data[dataIndex].tagName === data[dataIndex].name ? '' : data[dataIndex].tagName}
              </small>
            </p>
          ),
          filter: false
        },
      },
      {
        name: 'userRole',
        label: t('pages.editUser.userPermissions'),
        enum: UserRoleType,
        options: {
          filter: true,
          filterOptions: {
            renderValue: (type) => t(`enums.userRoleTypeEnum.${type}`),
            names: UserRoleType.enums.map((item) => item.value),
            logic: (prop, filters) => filters.includes(t(`enums.userRoleTypeEnum.${prop}`)),
          },
          customFilterListOptions: {
            render: (type) => t(`enums.userRoleTypeEnum.${type}`),
          },
          customBodyRenderLite: 
          (dataIndex) => (
            <Box display="flex" rowGap={1} flexWrap='wrap' maxWidth='200px' columnGap={1}> 
              {data[dataIndex].userPermissions.length === 0 ?
                <Chip label={t(`enums.userRoleType.ATTENDEE`) ?? 'não definido'} size="small" />
                : data[dataIndex].userPermissions?.map(({ userRole }) =>
                  <>
                    <Chip label={t(`enums.userRoleType.${userRole}`) ?? 'não definido'} size="small" key={dataIndex} />
                  </>
                )}
            </Box>
          ),
        },
        filter: true,
      },
      {
        name: 'email',
        label: t('pages.userList.columns.email'),
        options: {
          filter: false,
        },
      },
      // {
      //   name: 'cellPhone',
      //   label: t('pages.userList.columns.cellPhone'),
      //   options: {
      //     filter: true,
      //     filterOptions: {
      //       renderValue: (value) => (value == null || value === '' ? t(`enums.empty.EMPTY`) : value),
      //     },
      //     customFilterListOptions: {
      //       render: (value) => (value == null || value === '' ? t(`enums.empty.EMPTY`) : value),
      //     },
      //     customBodyRenderLite: (dataIndex) =>
      //       data[dataIndex].cellPhone == null || data[dataIndex].cellPhone === '' ? '-' : data[dataIndex].cellPhone,
      //   },
      // },
      ...(!isSecretary || currentUser?.admin
        ? [
          {
            name: 'enabled',
            label: t('pages.userList.columns.enable'),
            options: {
              filter: true,
              filterOptions: {
                renderValue: (enabled) => t(`enums.activated.${enabled}`),
                names: [true, false],
                logic: (prop, filters) => filters.includes(prop),
              },
              customFilterListOptions: {
                render: (enabled) => t(`enums.enabled.${enabled}`),
              },
              sort: true,
              searchable: true,
              customBodyRenderLite: (dataIndex) => (
                <SwitchW
                  checked={data[dataIndex].enabled}
                  onChange={(e) => changeEnabled(dataIndex, e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                  name="preview"
                  primary
                />
              ),
            },
          },
        ]
        : []),
      {
        name: 'confirmed',
        label: t('pages.userList.columns.confirmed'),
        options: {
          filter: true,
          filterOptions: {
            renderValue: (confirmed) => t(`enums.confirmedBool.${confirmed}`),
            names: [true, false],
            logic: (prop, filters) => filters.includes(prop),
          },
          customFilterListOptions: {
            render: (enabled) => t(`enums.confirmedBool.${enabled}`),
          },
          sort: true,
          searchable: true,
          customBodyRender: (confirmed) => t(`enums.confirmedBool.${confirmed}`),
        },
      },
      {
        name: 'country',
        label: t('pages.userList.columns.country'),
        options: {
          filter: true,
          sort: true,
          searchable: true,
          customBodyRenderLite: (dataIndex) =>
            data[dataIndex].country == null || data[dataIndex].country === '' ? '-' : data[dataIndex].country,
        },
      },
      // {
      //   name: 'registrations',
      //   label: t('pages.userList.columns.editions'),
      //   options: {
      //     filter: false,
      //     sort: false,
      //     searchable: false,
      //     customBodyRenderLite: (dataIndex) => chips[data[dataIndex].id]?.map((edition) => <Chip label={edition} />),
      //   },
      // },
      ...(!isSecretary || currentUser?.admin
        ? [
          {
            name: 'actions',
            label: ' ',
            options: {
              filter: false,
              sort: false,
              searchable: false,
              viewColumns: false,
              customBodyRenderLite: () => (
                <IconButton
                  tabIndex={-1}
                  onClick={
                    isInternalPage
                      ? (rowData, rowMeta) => {
                        setLink(EDIT_USER);
                        setOpenDialog(true);
                        setInternalID(data[rowMeta.dataIndex].id);
                      }
                      : undefined
                  }
                >
                  <EditIcon />
                </IconButton>
              ),
            },
          },
        ]
        : []),
    ],
    [changeEnabled, currentUser?.admin, data, isInternalPage, isSecretary, t ]
  );

  const options = {
    selectableRows: 'none',
    selectToolbarPlacement: 'none',
    onRowClick: isInternalPage
      ? (rowData, rowMeta) => {
        setLink(EDIT_USER);
        setOpenDialog(true);
        setInternalID(data[rowMeta.dataIndex].id);
      }
      : undefined,
    customToolbar: () => {
      if (!isSecretary || currentUser?.admin) {
        return (
            <Button
              variant='contained'
              color="primary" 
              endIcon={<Add />}
              onClick={() => {
                if (isInternalPage) {
                  setInternalID(null);
                  setLink(EDIT_USER);
                  setOpenDialog(true);
                  return;
                }
                history.push('/cli/user');
              }}
            >
              {t('pages.userList.tooltip.add')}
            </Button>
        );
      }
      return <></>;
    },
  };

  return (
    <>
      <FormDialog openDialog={openDialog} setOpenDialog={setOpenDialog} link={link} linkProps={{ isInternalPage: true, id: internalID }} />
      {!isInternalPage && <Toolbar title={[t('layouts.sidebar.records'), t('layouts.sidebar.users')]} hasArrowBack />}
      <Box p={2} flexDirection="row" display="flex" flexWrap="wrap" alignItems="center">
        <StyledCard p={0} elevation={4}>
          <ServerSideList
            {...{
              columns,
              options,
              data,
              setData,
            }}
            enableDefaultUseEffect
            textLabelsCod="userList"
            defaultOnRowClickURL={(!isSecretary || currentUser?.admin) && '/cli/user'}
            defaultService={UserService}
            onRowsDeleteErrorToast="toastes.deleteErrors"
            onRowsDeleteToast="toastes.deletes"
            defaultSortOrder={{ name: 'name', direction: 'asc' }}
            fluxListeners={useMemo(() => [FLUX_USERS], [])}
            filterData
            filterPath="name"
          />
        </StyledCard>
      </Box>
    </>
  );
};

export default UserList;
