import { CheckCircleOutline, ContentCopy, HowToReg } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ManipulateStep } from '../registration';
// eslint-disable-next-line no-unused-vars
import { useCallback, useContext, useEffect, useState } from 'react';
import ButtonW from '../../../components/wrapper/ButtonW';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { useToast } from '../../../components/context/toast/ToastContext';
import useLocation from '../../../components/hook/useLocation';
// import RegistrationService from '../../../services/RegistrationService';
import { useEditionChange } from '../../../components/context/EditionChangeContext';
// import { useUserChange } from '../../../components/context/UserChangeContext';

const Step6 = ({ item }) => {
  const { renderUrl } = useEditionChange();
  const { t } = useTranslation();
  const { stepValues } = useContext(ManipulateStep);
  const pixData = stepValues[6];
  const { formatLocaleString } = useLocation();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const { addToast } = useToast();

  // const { currentEdition } = useEditionChange();
  // const { currentUser } = useUserChange();
  // const { regId } = item;
  // eslint-disable-next-line no-unused-vars
  const [paymentStatus, setPaymentStatus] = useState({payed:true});

  console.log(item)

  // const handleVerifyPayment = useCallback(async () => {
  //   try {
  //     await RegistrationService.verifyPayment(currentEdition?.id, currentUser?.id)
  //       .then((response) => {
  //         // console.log(response);
  //         setPaymentStatus(response.registrationData);
  //       })
  //       .catch((error) => console.log(error));
  //   } catch (error) {
  //     addToast({ body: t('toastes.chargeError'), type: 'error' });
  //   }
  // }, [currentEdition?.id, currentUser?.id, addToast, t]);

  // useEffect(() => {
  //   handleVerifyPayment();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // console.log(pixData);
  // console.log(paymentStatus);

  return (
    <Box height={'100%'} width={'100%'} pt={5} pb={15} flexDirection={'column'} display="flex" justifyContent="center" alignItems="center">
      <CheckCircleOutline color="success" sx={{ fontSize: 200 }} />
      <Typography textAlign={'center'} width={{ md: '25%', xs: '50%' }} color={'success.main'} variant={'h4'} fontWeight={'bold'}>
        {paymentStatus?.payed ? t('pages.registration.step6.title2') : t('pages.registration.step6.title1')}
        <br />
      </Typography>
      <Typography textAlign={'center'} width={{ md: '25%', xs: '50%' }} color={'success.main'} variant={'h6'} fontWeight={'bold'}>
        {paymentStatus?.payed ? t('pages.registration.step6.textRow2') : t('pages.registration.step6.textRow1')}
      </Typography>
      <ButtonW
        variant="contained"
        color="primary"
        size="small"
        startIcon={<HowToReg />}
        sx={{ fontSize: 14, px: 3 }}
        onClick={() => {
          window.location.href = renderUrl('/cli/myregistration');
        }}
      >
        {t('pages.myRegistration.payment.goRegistration')}
      </ButtonW>

      {pixData && !paymentStatus?.payed && (
        <Box width={'50%'}>
          <Typography fontWeight="bold" py={1}>
            {t('pages.myRegistration.payment.pixCopyText')}
          </Typography>
          <Box p={1} bgcolor="#cAcAcA" sx={{ borderRadius: 1 }}>
            <Typography fontSize={12}>{pixData?.pixCopyPaste}</Typography>
          </Box>
          <Box display={'flex'} mt={1} justifyContent={'center'}>
            <ButtonW
              variant="contained"
              color="primary"
              size="small"
              startIcon={<ContentCopy />}
              sx={{ borderRadius: 28, fontSize: 12, px: 3 }}
              onClick={() => {
                copyToClipboard(pixData?.pixCopyPaste);
                if (copiedText !== '') {
                  addToast({ body: 'Código copiado!', type: 'success' });
                }
              }}
            >
              {t('pages.myRegistration.payment.copyToClipboard')}
            </ButtonW>
          </Box>
          <Box pt={1} width="100%" display="flex" flexDirection="column" justifyContent="center" alignItens="center" pb={0}>
            <Typography fontWeight="bold">{t('pages.myRegistration.payment.pixQrCode')}</Typography>
            <Typography>
              Leia o QR Code abaixo para realizar o pagamento, voce tem até {formatLocaleString(pixData?.pixExpirationDate)}:
            </Typography>
            <img
              src={`https://sandbox.api.pagseguro.com/qrcode/${pixData?.qrcodeId}/png`}
              width="300px"
              height="300px"
              alt="QrCode"
              style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '8px' }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Step6;
