import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useToast } from '../context/toast/ToastContext';

const useFormUtils = () => {
  const { t, i18n } = useTranslation();
  const { addToast } = useToast();

  const defaultLanguage = (i18n.language !== 'pt-BR' && i18n.language !== 'en-US') ? 'pt-BR' : i18n.language;

  return {
    validateMask: (value, country) => {
      if (country !== 'BR') {
        return true;
      }
      return !value?.includes('_');
    },
    validateUrl: (value) => {
      const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/.*)?$/;
      return regex.test(value);
    },
    scoreWords: useMemo(
      () => Object.entries(i18n.getDataByLanguage(defaultLanguage).translation.enums.passwordStrength).map((e) => e[1]),
      [i18n, defaultLanguage]
    ),
    validateUnique: (service, urlFragment, value, property, ignore) => {
      if (value == null || value === '' || ignore) {
        return true;
      }
      return service.validateUnique(value, urlFragment, property).then((response) => {
        if (response.status === 200) {
          return !response.data;
        }
        addToast({ body: t('toastes.fetchError'), type: 'error' });
        return true;
      });
    },
    validateUniqueEdition: (service, urlFragment, value, property, edition, ignore) => {
      if (value == null || value === '' || ignore) {
        return true;
      }
      return service.validateUniqueEdition(value, urlFragment, property, edition).then((response) => {
        if (response.status === 200) {
          return !response.data;
        }
        addToast({ body: t('toastes.fetchError'), type: 'error' });
        return true;
      });
    },
    getHelperText: (errors) => {
      if (!errors) return undefined;

      if (errors.type === 'max') {
        let start;
        let end;
        let numberStr;

        switch (defaultLanguage) {
          /**
           * msg de erro .max() customizada para os idiomas do sistema
          */
          case 'en-US':
            start = errors.message.indexOf('most') + 'most'.length;
            end = errors.message.indexOf('characters');
            numberStr = errors.message.substring(start, end).trim();
            break;
          case 'pt-BR':
            start = errors.message.indexOf('most') + 'most'.length;
            end = errors.message.indexOf('characters');
            numberStr = errors.message.substring(start, end).trim();
            break;
          case 'es-ES':
            start = errors.message.indexOf('máximo') + 'máximo'.length;
            end = errors.message.indexOf('caracteres');
            numberStr = errors.message.substring(start, end).trim();
            break;
          default:
            start = errors.message.indexOf('most') + 'most'.length;
            end = errors.message.indexOf('characters');
            numberStr = errors.message.substring(start, end).trim();
        }
        return t('yup.maxValue', { count: numberStr });
      }

      return (
        (errors.type === 'required' && t('yup.requiredField')) ||
        (errors.type === 'unique' && t('yup.uniqueField')) ||
        (errors.type === 'email' && t('yup.emailFormat')) ||
        (errors.type === 'min' && t('yup.positiveError')) ||
        (errors.type === 'url' && t('yup.urlFormat')) ||
        (errors.type === 'typeError' && t('yup.requiredField')) ||
        (errors.type === 'registrationType' && t('yup.dateIntervalError')) ||
        (errors.type === 'integer' && t('yup.integerError')) ||
        (errors.type === 'match' && t('yup.maskError')) ||
        (errors.type === 'passwordStrength' && t('yup.passwordStrength')) ||
        (errors.type === 'matchPassword' && t('yup.passwordEqual')) ||
        (errors.type === 'minArray' && t('yup.requiredField')) ||
        (errors.type === 'minDate' && t('yup.pastFinalDateError')) ||
        (errors.type === 'passwordMin' && t('yup.passwordMin')) ||
        (errors.type === 'resourceTimeConflict' && t('yup.resourceTimeConflict')) ||
        (errors.type === 'minExpYear' && t('yup.wrongExpYear')) ||
        (errors.type === 'validMonth' && t('yup.validMonth')) ||
        (errors.type === 'wrongCardNumber' && t('yup.wrongCardNumber')) ||
        (errors.type === 'wrongSecurityCode' && t('yup.wrongSecurityCode')) ||
        (errors.type === 'matches' && (errors.ref.name === 'name' || errors.ref.name === 'tagName' || errors.ref.name === 'note')) && t('yup.onlyAlphabeticalCharactersAllowed') ||
        (errors.type === 'matches' && errors.ref.name === 'applicantContact' && t('yup.onlyNumbersAllowed')) ||
        (errors.type === 'matches' && errors.ref.name === 'title' && t('yup.noSpecialCharactersAllowed'))
      );
    },
  };
};

export default useFormUtils;
