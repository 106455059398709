import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddShoppingCart, ShoppingCart } from '@mui/icons-material';
import CardActivity from '../../../components/card-activity/CardActivity';
import { CartContext, ManipulateStep } from '../registration';
import ButtonsSteps from '../../../components/buttons-steps/ButtonsSteps';
import CardMainActivity from '../../../components/card-activity/CardMainActivity';
import useLocation from '../../../components/hook/useLocation';
import CustomDialog from '../../../components/custom-dialog/CustomDialog';

const Step2 = ({ item, lectures, nonLectures }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isButtonClicked1, setIsButtonClicked1] = useState(false);
  const [isButtonClicked2, setIsButtonClicked2] = useState(true);

  const { cart, addItem, updateCart } = useContext(CartContext);
  const { activeStep, setActiveStep, updateStepValues } = useContext(ManipulateStep);
  const { formatCurrency } = useLocation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmHalfPrice = () => {
    setIsButtonClicked1(true);
    setIsButtonClicked2(false);
    updateStepValues(2, { registrationType: 1 });
    handleClose();
  };

  const handleSetRegistration = (option) => {
    if (option === 1) {
      handleClickOpen();
    } else if (option === 0) {
      setIsButtonClicked1(false);
      setIsButtonClicked2(true);
      updateStepValues(2, { registrationType: option });
    }
  };

  const handleNext = () => setActiveStep(activeStep + 1);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    resetButtonStates(); // Resetar estados ao voltar para o Step 2
  };

  const resetButtonStates = () => {
    setIsButtonClicked1(false);
    setIsButtonClicked2(true);
    updateStepValues(2, { registrationType: 0 });
  };

  useEffect(() => {
    updateCart(cart);
  }, [cart, updateCart]);

  // Define o valor padrão para a entrada inteira no carregamento inicial
  useEffect(() => {
    resetButtonStates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isItemInCart = (id) => {
    if (!cart) return false;
    return cart.some((item) => item.id === id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          {`${t('pages.registration.step2.title1')}  ${item?.title}`}
        </Typography>
        <Typography sx={{ width: { xs: '100%', md: '25%' }, lineHeight: 1.2 }} mb={1}>
          {t('pages.registration.step2.subtitle1')}
        </Typography>
      </Grid>
      <React.Fragment>
        <Grid item xs={12}>
          <CardMainActivity
            minWidth="100%"
            direction="row"
            activity={{
              name: item?.title,
              price: item?.price,
              description: t('pages.registration.step2.description'),
            }}
            activities={lectures}
            idealCardHeight="600px"
            conflicts={[]}
            enableHalfPrice={item?.enableHalfPrice}
            buttonOptions={[
              {
                title: isButtonClicked1 ? `${t('pages.registration.step2.selected')} ${formatCurrency(item?.price / 2)}` : `${t('pages.registration.step2.halfTicket')} ${formatCurrency(item?.price / 2)}`,
                color: 'secondary',
                variant: 'outlined',
                icon: isButtonClicked1 ? <ShoppingCart /> : <AddShoppingCart />,
                onClick: () => handleSetRegistration(1)              
              },
              {
                title: isButtonClicked2 ? `${t('pages.registration.step2.selected')} ${formatCurrency(item?.price)}` : `${t('pages.registration.step2.ticket')} ${formatCurrency(item?.price)}`,
                color: 'secondary',
                variant: 'contained',
                icon: isButtonClicked2 ? <ShoppingCart /> : <AddShoppingCart />,
                onClick: () => handleSetRegistration(0)
              },
            ]}
          />
        </Grid>

        <CustomDialog
          maxWidth="800px"
          open={open}
          onClose={handleClose}
          title={t('pages.registration.step2.modal.modalContent')}
          content={
            <>              
              <Typography gutterBottom>
                {t('pages.registration.step2.modal.modalContent1')}
              </Typography>
              <ul>
                <li>{t('pages.registration.step2.modal.modalContent2')}</li>
                <li>{t('pages.registration.step2.modal.modalContent3')}</li>
                <li>{t('pages.registration.step2.modal.modalContent4')}</li>
              </ul>
              <Typography gutterBottom variant="body2" style={{ fontStyle: 'italic' }}>
                {t('pages.registration.step2.modal.modalContent5')}
              </Typography>
            </>
          }     
          buttonText={t('pages.registration.step2.agreeTerms')}
          buttonOnClick={handleConfirmHalfPrice}
          buttonErrorText={t('pages.registration.step2.cancel')}
          buttonErrorOnClick={handleClose}
        />
      </React.Fragment>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          {t('pages.registration.step2.title2')}
        </Typography>
        <Typography sx={{ width: { xs: '100%', md: '25%' }, lineHeight: 1.2 }} mb={1}>
          {t('pages.registration.step2.subtitle2')}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        {nonLectures.length === 0 ? (
          <Box height={300} width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6">{t('pages.registration.step2.noActivities')}</Typography>
          </Box>
        ) : (
          nonLectures.map((activity) => (
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={activity.id}>
              <CardActivity
                activity={activity}
                minHeight="350px"
                buttonOptions={{
                  title: isItemInCart(activity.id)
                    ? t('pages.registration.step2.card.buttonOff')
                    : t('pages.registration.step2.card.buttonOn'),
                  color: isItemInCart(activity.id) ? 'success' : 'secondary',
                  icon: isItemInCart(activity.id) ? <ShoppingCart /> : <AddShoppingCart />,
                  onClick: () => addItem(activity),
                  disabled: isItemInCart(activity.id),
                }}
              />
            </Grid>
          ))
        )}
      </Grid>
      <ButtonsSteps handleNext={handleNext} handleBack={handleBack} />
    </Grid>
  );
};

export default Step2;
